import React from 'react';
import { connect } from 'react-redux';
import styled from 'react-emotion';
import Layout from '../../../components/layout';
import Container from '../../../components/container';
const HeadNotif = styled.h2`
  margin-top:1rem;
`
const ErrorCheckoutPage = (props) => {
    props.clearCart();

    return (
        <Layout>
            <Container>
                <HeadNotif>
                    Silahkan lakukan pembayaran anda. <br/>Detail pembayaran telah kami kirim ke email anda.
                </HeadNotif>
            </Container>
        </Layout>
    )
}

const mapDispatchToProps = dispatch => ({
    clearCart: () => dispatch({ type: 'CLEAR_CART' })
})

export default connect(null, mapDispatchToProps)(ErrorCheckoutPage);